import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
   return (
      <div className="footer" style={{padding: '3rem 1rem .3rem 1rem'}}>
         <Link to='/legal'>Mentions legales</Link>
      </div>
   );
}
