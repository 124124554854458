import React, { useState, useContext, useEffect } from 'react';
import _ from 'lodash-es';

import { Redirect } from 'react-router-dom';
import { Tile, TextInput } from 'carbon-components-react';
import { Formik, Field, Form } from 'formik';

import API from '../../contexts/api';
import notification from '../../utils/notification';

export default function Home() {
  const [redirect, setRedirect] = useState(null);
  const api = useContext(API);

  //
  // ─── API FUNCTIONS ──────────────────────────────────────────────────────────────
  //

  const asyncGetSurveyFromCode = async (code) => {
    const res = await api.get('access/' + code);
    return res.json();
  }

  const getSurveyFromCode = code => {
    code = _.toUpper(code);
    const tID = notification.info('Sending code', null, { autoClose: false });
    asyncGetSurveyFromCode(code).then(() => {
      notification.update(tID, {
        type: notification.TYPE.SUCCESS,
        title: 'Code found!',
        text: 'redirecting to the survey',
      });
      setRedirect('/access/'+code);
    }).catch(e => {
      if (e.message === 'Not Found') {
        notification.update(tID, {
          type: notification.TYPE.WARNING,
          title: `This code doesn't exists!`,
        });
      } else {
        console.error(e);
        notification.update(tID, {
          type: notification.TYPE.ERROR,
          title: 'Error!',
          text: 'An error occured while trying to acceed the code',
        });
      }
    });
  }

  //
  // ─── LIFE CYCLE ─────────────────────────────────────────────────────────────────
  //

  useEffect(() => {
    document.title = 'HNS Surveys';
  }, []);

  return (
    <div className="standardContent">
      <div className="codeboxContainer">
        <Tile className="bx--tile codebox">
          <h3 style={{ margin: '2em' }}>Please enter the survey code:</h3>

          <Formik
            onSubmit={(values) => {
              getSurveyFromCode(values.code)
            }}
          >
            {() => (
              <Form>
                <Field type="text" name="code">
                  {({ field }) => (
                    <TextInput
                      style={{ marginBottom: '4rem' }}
                      id={field.name}
                      labelText=""
                      hideLabel
                      autoFocus
                      placeholder="Code"
                      {...field}
                      value={_.toUpper(field.value)}
                    />
                  )}
                </Field>
              </Form>
            )}
          </Formik>

        </Tile>
      </div>

      {/*
       // ─── LOGIC ───────────────────────────────────────────────────────
       */}
      
      {/* redirection component */}
      {!!redirect && (
        <div>
          <Redirect push to={redirect} />
        </div>
      )}
    </div>
  );
}
