import React from 'react';

import { Header, HeaderNavigation } from 'carbon-components-react/es/components/UIShell';
import { Link } from 'react-router-dom';

const links = [
  {label: 'Home', to: '/home'},
  // {label: 'Campaigns', to: '/campaigns'},
  // {label: 'Manage', to: '/surveys'},
];

export default function Nav() {
  return (
    <Header aria-label="hns [Platform]">
      {/* We can't use HeaderName and HeaderMenuItem as we want to use React Router Links */}
      <Link className="bx--header__name" to="/" title="">
        <span className="bx--header__name--prefix">hns &nbsp;</span> [Platform]
      </Link>
      <HeaderNavigation aria-labelledby="hns [Platform]">

        {links.map(l => <CustomMenuItem key={l.to} to={l.to}>{l.label}</CustomMenuItem>)}

      </HeaderNavigation>
    </Header>
  );
}

function CustomMenuItem({to, className, children}){
  return (
    <li>
      <Link to={to} className={className+' bx--header__menu-item'} role="menuitem" tabIndex="0">{children}</Link>
    </li>
  );
}
