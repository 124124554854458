import React from 'react';
import { toast, cssTransition } from 'react-toastify';
import _ from 'lodash-es';

import { InlineNotification } from 'carbon-components-react';

// ────────────────────────────────────────────────────────────────────────────────

const defaultConf = {
  kind: toast.TYPE.INFO,
  autoClose: 5000,
}

// ────────────────────────────────────────────────────────────────────────────────

function info(title, text, params = {}) {
  const toastId = toast(
    <InlineToast
      kind="info"
      title={title}
      subtitle={text}
    />,
    {
      progressClassName: 'toastProgressInfo',
      ...params,
    }
  );

  return toastId;
}

function success(title, text, params = {}) {
  const toastId = toast(
    <InlineToast
      kind="success"
      title={title}
      subtitle={text}
    />,
    {
      progressClassName: 'toastProgressSuccess',
      ...params,
    }
  );

  return toastId;
}

function warn(title, text, params = {}) {
  const toastId = toast(
    <InlineToast
      kind="warning"
      title={title}
      subtitle={text}
    />,
    {
      progressClassName: 'toastProgressWarning',
      ...params,
    }
  );

  return toastId;
}

function error(title, text, params = {}) {
  const toastId = toast(
    <InlineToast
      kind="error"
      title={title}
      subtitle={text}
    />,
    {
      progressClassName: 'toastProgressError',
      ...params,
    }
  );

  return toastId;
}

function update(toastId, params = {}) {
  if (!!params.type) params.kind = params.type;
  if (!!params.text) params.subtitle = params.text;
  if (!params.progressClassName) params.progressClassName = progressStyle(params.kind)

  params = {
    ...defaultConf,
    ...params,
  }

  toast.update(toastId, {
    ...params,
    render: <InlineToast {...params} />,
    transition: cssTransition({
      enter: 'Toastify__flip-enter',
      exit: 'Toastify__bounce-exit--bottom-right',
    }),
  });
}

// ────────────────────────────────────────────────────────────────────────────────

function InlineToast({ kind = 'info', title, subtitle = "" }) {
  return (
    <InlineNotification
      className="notification"
      role="alert"
      kind={kind}
      title={title}
      subtitle={subtitle}
      hideCloseButton
    />
  );
}

function progressStyle(kind) {
  switch (kind) {
    case 'success':
      return 'toastProgressSuccess';
    case 'warning':
      return 'toastProgressWarning';
    case 'error':
      return 'toastProgressError'
    default:
      return 'toastProgressInfo'
  }
}

// ────────────────────────────────────────────────────────────────────────────────

const notification = {
  toast: toast,
  TYPE: _.omit(toast.TYPE, 'DEFAULT'),

  info: info,
  success: success,
  warn: warn,
  error: error,

  update: update,
}

export default notification;
