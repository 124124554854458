import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash-es';
import moment from 'moment';

import { Loading } from 'carbon-components-react';
import { Redirect } from 'react-router-dom';

import GeneratedForm from '../../components/generated-form/generated-form';

import API from '../../contexts/api';
import notification from '../../utils/notification';

export default function PublicSurvey({ match }) {
  const code = match.params.code;
  const [survey, setSurvey] = useState({});
  const [errorFetching, setErrorFetching] = useState(false);
  const [fetchingSurvey, setFetchingSurvey] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const api = useContext(API);

  //
  // ─── API FUNCTIONS ──────────────────────────────────────────────────────────────
  //

  async function asyncGetSurveyFromCode(code) {
    const res = await api.get('access/' + code);
    return res.json();
  }

  const getSurveyFromCode = code => {
    setFetchingSurvey(true);
    asyncGetSurveyFromCode(code).then(d => {
      setFetchingSurvey(false);
      setSurvey(d);
    }).catch(() => {
      setErrorFetching(true);
      notification.error('Error!', 'An error occured while trying to retrieve the survey');
      setFetchingSurvey(false);
    });
  }

  async function asyncPostResponse(response) {
    const res = await api.post('answer/' + code, {
      body: JSON.stringify(response),
    });
    return res.json();
  }

  const onSubmit = values => {
    let body = {
      completionTime: moment().diff(startTime) * 1000,
      answers: values,
    };

    const tID = notification.info('Submitting your answers', '', { autoClose: false });
    asyncPostResponse(body).then(() => {
      notification.update(tID, {
        type: notification.TYPE.SUCCESS,
        title: 'Thank you!',
        text: 'Your answers have been submitted',
      });
      setRedirect('/home');
    }).catch(e => {
      console.error(e);
      notification.update(tID, {
        type: notification.TYPE.ERROR,
        title: 'Error!',
        text: 'An error occured while trying to submit your response',
      });
    });
  }

  //
  // ─── LIFE CYCLE ─────────────────────────────────────────────────────────────────
  //

  useEffect(() => {
    document.title = (survey.name || 'answer survey') + ' - HNS Surveys';
  }, [survey]);

  useEffect(() => {
    getSurveyFromCode(code);
    setStartTime(moment());
  }, [match.params.code]);

  //
  // ─── RETURN ─────────────────────────────────────────────────────────────────────
  //

  if (_.size(survey) && !fetchingSurvey) {
    return (
      <div className="standardContent">
        <GeneratedForm formData={survey.formData} confirmBeforeSubmiting onSubmit={onSubmit} initialValues={survey.initialValues} language={survey.language} />

        {/*
         // ─── LOGIC ───────────────────────────────────────────────────────
         */}
        {/* redirection component */}
        {!!redirect && (
          <div>
            <Redirect push to={redirect} />
          </div>
        )}
      </div>
    );
  } else if (errorFetching) {
    return (
      <div className="standardContent">
        <p>No survey found</p>
      </div>
    );
  }

  return <Loading />;
}
