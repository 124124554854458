import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ky from 'ky';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.scss';
import API from './contexts/api';
import Nav from './components/nav';
import Footer from './components/footer';
// import Surveys from './pages/surveys';
// import Survey from './pages/survey';
// import ManageSurvey from './pages/manage-survey';
// import Campaigns from './pages/campaigns';
// import Campaign from './pages/campaign';
import PublicHome from './pages/public/home';
import PublicSurvey from './pages/public/survey';
import Legal from './pages/public/legal';

const env = process.env.NODE_ENV;
const api_url = (env === 'development') ? 'http://localhost:7740' : '/api';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Router>
          <Nav />

          <API.Provider value={ky.extend({ prefixUrl: api_url, throwHttpErrors: false })}>
            <div className="appContent">
              <Switch>
                <Route exact path="/" component={PublicHome} />

                {/* <Route path="/surveys/manage/:id" component={ManageSurvey} />
                <Route path="/surveys/:id" component={Survey} />
                <Route path="/surveys" component={Surveys} />

                <Route path="/campaigns/:id" component={Campaign} />
                <Route path="/campaigns" component={Campaigns} /> */}

                {/* Public routes */}
                <Route path="/home" component={PublicHome} />
                <Route path="/access/:code" component={PublicSurvey} />
                <Route path="/legal" component={Legal} />
              </Switch>
            </div>
            <Footer />
          </API.Provider>

          <ToastContainer
            className="notificationContainer"
            closeButton={false}
            toastClassName="toast"
            bodyClassName="toastBody"
          />
        </Router>
      </div>
    );
  }
}

export default App;
