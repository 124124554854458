import React, { useState } from 'react';
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';

import { Formik, Form, Field } from 'formik';
import { StructuredListWrapper, StructuredListHead, StructuredListBody, StructuredListRow, StructuredListCell, Button, Modal } from 'carbon-components-react';

import { GTextInput, GTextArea, GDatePicker, GRadioGroup } from './inputs';
import Debug from './debug';

const _ = deepdash(lodash);

export default function GeneratedForm({ formData, onSubmit, submitText, confirmBeforeSubmiting = false, debug = false, initialValues, language }) {
  const [confirmData, setConfirmData] = useState({});
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  formData = formData ? formData : [];

  if (!formData.length) {
    return null;
  }

  const confirmBeforeSubmit = (values, formikBag) => {
    const cd = {
      values: values,
      formikBag: formikBag
    }

    setConfirmData(cd);
    setShowConfirmModal(true);
  }

  let formInitialValues = _
    .chain(formData)
    .index({ childrenPath: 'children', leavesOnly: true })
    .filter('name')
    .map()
    .reduce((res, e) => {
      let initValue = e.value || '';
      return _(res).set(e.name, initValue).value();
    }, {})
    .value();

  let mergedInitialValues = _.merge(formInitialValues, initialValues);

  return (
    <div>
      <Formik
        initialValues={mergedInitialValues}
        onSubmit={confirmBeforeSubmiting ? confirmBeforeSubmit : onSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="form" style={{ display: 'flex', flexDirection: 'column' }}>
            {formData.map((e, i) => <GeneratedElement key={i} element={e} />)}

            {/* <Button type="submit" disable={isSubmitting ? "" : null} kinds="primary">Submit</Button> */}
            <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '0 3rem' }}>
              {!!onSubmit && (
                <Button
                  type="submit"
                  disable={isSubmitting ? "" : null}
                  kinds="primary"
                >
                  {submitText || (language === 'en' ? 'Send' : 'Envoyer')}
              </Button>
              )}
            </div>

            {!!debug && (
              <Debug />
            )}
          </Form>
        )}
      </Formik>

      {/*
       // ─── LOGIC ───────────────────────────────────────────────────────
       */}

      {/* send response modal */}
      <Modal
        id="sendingModal"
        open={showConfirmModal}
        shouldSubmitOnEnter
        modalHeading={language === 'en' ? 'Confirm sending' : "Confirmez l'envoi des réponses"}
        modalAriaLabel={language === 'en' ? 'Confirm sending' : "Confirmez l'envoi des réponses"}
        primaryButtonText={language === 'en' ? 'Yes, send them' : "Oui, envoyer"}
        secondaryButtonText={language === 'en' ? 'Cancel' : "Annuler"}
        iconDescription={language === 'en' ? 'Close modal' : "Fermer la popup"}
        onRequestClose={() => setShowConfirmModal(false)}
        onRequestSubmit={() => {
          setShowConfirmModal(false);
          onSubmit(confirmData.values, confirmData.formikBag);
        }}
        onSecondarySubmit={() => setShowConfirmModal(false)}
      >
        <p className="bx--modal-content__text">
          {language === 'en' ? "Do you want to send your answers? You won't be able to modify them afterwards." : 'Voulez-vous envoyer vos réponses ? Vous ne pourrez plus les modifier après coup.'}
        </p>
      </Modal>
    </div>
  );
}

//
// ─── INPUTS GENERATION ──────────────────────────────────────────────────────────
//

function GeneratedElement({ element }) {
  if (!!element.name) {
    return (
      <div className="formElement">
        <Field name={element.name}>
          {({ field, form }) => <GeneratedInput field={field} form={form} {...element} />}
        </Field>
      </div>
    );
  }

  switch (element.type) {
    case 'text':
      return <p className="formElement">{element.text || ''}</p>;

    case 'title':
      switch (element.level) {
        case 2:
          return <h2 className="formElement">{element.text || ''}</h2>;

        case 3:
          return <h3 className="formElement">{element.text || ''}</h3>;

        default:
          return <h1 className="formElement">{element.text || ''}</h1>;
      }

    case 'card':
      return (
        <div className="bx--tile formWrapper">
          {(element.children || []).map((c, i) => <GeneratedElement key={i} element={c} />)}
        </div>
      );

    case 'group':
      return (
        <div
          className="formWrapper group"
          style={{
            gridTemplateColumns: _(element.children).map(e => (e.groupSize || 1) + 'fr').join(' '),
          }}
        >
          {(element.children || []).map((c, i) =>
            <GeneratedElement key={i} element={c} />
          )}
        </div>
      );

    case 'div':
      return (
        <div className="formWrapper">
          {(element.children || []).map((c, i) =>
            <GeneratedElement key={i} element={c} />
          )}
        </div>
      );

    case 'questions':
      return (
        <StructuredListWrapper className="formWrapper">
          <StructuredListHead>
            <StructuredListRow head>
              <StructuredListCell head>{element.title}</StructuredListCell>
            </StructuredListRow>
          </StructuredListHead>
          <StructuredListBody>

            {(element.children || []).map((c, i) =>
              <StructuredListRow key={i}>
                <StructuredListCell>{c.title}</StructuredListCell>
                <StructuredListCell>

                  <GeneratedElement element={{ ...c, title: null }} />

                </StructuredListCell>
              </StructuredListRow>
            )}

          </StructuredListBody>
        </StructuredListWrapper>
      );

    default:
      return null;
  }
}

function GeneratedInput(props) {
  switch (props.type) {
    case 'textInput':
      return <GTextInput {...props} />;

    case 'textArea':
      return <GTextArea {...props} />;

    case 'date':
      return <GDatePicker {...props} />;

    case 'radios':
      return <GRadioGroup {...props} />;

    default:
      return null;
  }
}
