import React from 'react';
import _ from 'lodash-es';

import { TextInput, TextArea, DatePicker, DatePickerInput, FormGroup, RadioButtonGroup, RadioButton } from 'carbon-components-react';


export function GTextInput({ name, label, placeholder, field, form, className, disabled }) {
  const props = {
    id: name,
    labelText: label === undefined ? _.capitalize(name) : label ||  ' ',
    placeholder: placeholder,
    invalid: shouldShowError(form, field),
    invalidText: _.get(form.errors, field.name, ''),
    disabled: disabled,
    className,
    ...field,
  };

  return <TextInput {...props} />;
}

export function GTextArea({ name, label, placeholder, field, form, className, disabled }) {
  const props = {
    id: name,
    labelText: label === undefined ? _.capitalize(name) : label ||  ' ',
    placeholder: placeholder,
    invalid: shouldShowError(form, field),
    invalidText: _.get(form.errors, field.name, ''),
    disabled: disabled,
    className,
    ...field,
  };

  return <TextArea {...props} />;
}

export function GDatePicker({ name, label, placeholder, pattern, field, form, className, disabled }) {
  const datePickerProps = {
    id: 'date-' + name,
    datePickerType: 'single',
    dateFormat: 'Y-m-d',
    onChange: (d, v) => {
      form.setFieldValue(field.name, v)
    },
    className,
  };

  const datePickerInputProps = {
    id: 'dateInput-' + name,
    labelText: label === undefined ? _.capitalize(name) : label ||  null,
    placeholder: placeholder || 'yyyy-mm-dd',
    pattern: pattern || '\\d{4}-\\d{1,2}-\\d{1,2}',
    invalid: shouldShowError(form, field),
    invalidText: _.get(form.errors, field.name, ''),
    disabled: disabled,
    onClick: e => {
      form.setFieldValue(field.name, e.target.value)
    },
    ...field,
  }

  return (
    <DatePicker {...datePickerProps} >
      <DatePickerInput {...datePickerInputProps} />
    </DatePicker>
  );
}

export function GRadioGroup({ name, label, options, field, form, className, disabled }) {
  options = (typeof options === 'object' && !!options.length) ? options : [];

  const formGroupProps = {
    legendText: label === undefined ? _.capitalize(name) : label ||  null,
  };

  const radioGroupProps = {
    name: name,
    valueSelected: field.value,
    onChange: v => form.setFieldValue(field.name, v),
    className,
  };

  const radiosProps = _(options).map(o => {
    let text, value;
    if (typeof o === 'object') {
      text = o.text;
      value = o.value;
    } else {
      text = _.capitalize(o);
      value = o;
    }

    return {
      id: name + '-' + value,
      key: name + '-' + value,
      labelText: text,
      value: value,
      disabled: disabled,
      onClick: () => form.setFieldTouched(field.name),
    };
  }).value();

  if (!!formGroupProps.legendText) {
    return (
      <FormGroup {...formGroupProps} >
        <RadioButtonGroup {...radioGroupProps} >
          {radiosProps.map(p => <RadioButton {...p} />)}
        </RadioButtonGroup>
      </FormGroup>
    );
  } else {
    return (
      <RadioButtonGroup {...radioGroupProps} >
        {radiosProps.map(p => <RadioButton {...p} />)}
      </RadioButtonGroup>
    );
  }
}

// ────────────────────────────────────────────────────────────────────────────────
// ────────────────────────────────────────────────────────────────────────────────
// ────────────────────────────────────────────────────────────────────────────────


// Inputs that have been touched and that have errors should show their error.
function shouldShowError(form, field) {
  const error = _.get(form.errors, field.name, false);
  const touched = _.get(form.touched, field.name, false);

  return (touched && error);
};
